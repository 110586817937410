import React from 'react'
import { graphql } from 'gatsby'
import Modal from 'react-modal'
import { Seo } from '../components/base'

import { Layout, OfferOverview } from '../components'

Modal.setAppElement('#___gatsby')

const OfferPreviewPage = ({ data, pageContext, location }) => {
  const languages = { pageContext }
  const { seo } = data.sanityOfferPreviewPage.nodes[0]

  const { name = '', address: { city = '', street = '' } = {} } =
    data?.sanityMarketData ?? {}

  const seoTitle = data?.sanityMarketData
    ? `${seo?.metaTitle} für ${name}, ${city}, ${street}`
    : seo?.metaTitle // prevents empty seo string if url is root/angebotsvorschau (when no specific market is addressed)

  return (
    <>
      <Layout translations={languages}>
        <Seo
          title={seoTitle}
          description={seo?.metaDescription}
          image={seo?.ogImage?.asset?.url}
        />
        <OfferOverview
          isPreview
          data={{ ...data, ...pageContext }}
          location={location}
        />
      </Layout>
    </>
  )
}

export default OfferPreviewPage

export const offers = graphql`
  query (
    $marketId: String!
    $dateToday: Date!
    $campaignEnd: Date!
    $language: String! = "de_de"
    $land: String!
  ) {
    sanityOfferPreviewPage: allSanityOfferPreviewPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        offerHandouts {
          ...OfferPdfQuery
        }
        overviewHeadline
        overviewSubline
        noMarketMessage
        myMarketLinkLabel
        overviewSublineMarket
        filterButtonText
        downloadButtonText
        legalDisclaimer
        _rawNoSearchResultsText
        title
        subtitle
        seo {
          ...SeoQuery
        }
        miniTeaser {
          title
          subline
          url
          color
          urlExtern
          image {
            ...ImageObjectQuery
          }
          reference {
            ... on SanityLandingPage {
              title
              basePath
              slug {
                current
              }
            }
            ... on SanityPost {
              title
              basePath
              category
              slug {
                current
              }
            }
            ... on SanityNewProductFeaturePage {
              title
              slug {
                current
              }
            }
            ... on SanityCareerPage {
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
    sanityMarketOffers: allSanityOffer(
      filter: {
        hidden: { nin: true }
        marketIdList: { marketIds: { in: [$marketId] } }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
      sort: {
        fields: [
          customCardColor___order
          validfrom
          articleGroup___productGroup___order
          title
          brand
        ]
      }
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }

    sanityAllPublishDates: allSanityOffer {
      nodes {
        publishAt
        validfrom
      }
    }

    sanityAllOffers: allSanityOffer(
      filter: {
        hidden: { nin: true }
        global: { eq: true }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
      sort: {
        fields: [
          customCardColor___order
          validfrom
          articleGroup___productGroup___order
          title
          brand
        ]
      }
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }
    bmvOnlyPreviewOffers: allSanityOffer(
      filter: {
        hidden: { nin: true }
        bmvOnly: { eq: true }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
      sort: {
        fields: [
          customCardColor___order
          validfrom
          articleGroup___productGroup___order
          title
          brand
        ]
      }
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }
    sanityMarketData: sanityMarket(marketId: { eq: $marketId }) {
      ...MarketQuery
    }
    sanityGetPreviewCountMarketNoGlobal: allSanityOffer(
      filter: {
        hidden: { nin: true }
        global: { eq: false }
        marketIdList: { marketIds: { in: [$marketId] } }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
    ) {
      totalCount
    }

    sanityGetPreviewCountGlobal: allSanityOffer(
      filter: {
        global: { eq: true }
        hidden: { nin: true }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
    ) {
      totalCount
    }
    sanityGetPreviewCountBmv: allSanityOffer(
      filter: {
        bmvOnly: { eq: true }
        hidden: { nin: true }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
    ) {
      totalCount
    }
  }
`
